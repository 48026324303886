import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login } from './api';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      const { token, user } = response.data;
      onLogin(token, user);
      if (user.role === 'Global Administrator') {
        navigate('/users');
      } else {
        navigate('/holidays-request');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Identifiant incorrect, veuillez recommencer ou contacter le support.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
      <div className="flex justify-center mb-6">
          <img src="/tripaklogo.png" alt="Tripak HR Logo" className="h-16" />
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center">Tripak HR Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Login
          </button>
        </form>
        {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
        <div className="mt-4 text-center">
          <Link to="/reset_password" className="text-blue-500 hover:text-blue-700">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
