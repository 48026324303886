import React, { useState } from 'react';
import { requestResetPassword } from './api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const RequestResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await requestResetPassword(email);
      toast.success(response.data.message); // Utiliser le message renvoyé par l'API
    } catch (error) {
      toast.error('Failed to send reset link');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
      <div className="flex justify-center mb-6">
          <img src="/tripaklogo.png" alt="Tripak HR Logo" className="h-16" />
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Send Reset Link
          </button>
        </form>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-blue-500 hover:text-blue-700">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestResetPassword;
