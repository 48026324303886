import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import RequireAuth from './RequireAuth';
import Layout from './Layout';
import { setAuthToken } from './api';
import HolidaysRequest from './Holidays_request'; // Import HolidaysRequest component
import Users from './Users'; // Import Users component
import RequestResetPassword from './RequestResetPassword'; // Import RequestResetPassword component
import ResetPassword from './ResetPassword'; // Import ResetPassword component
import AdminApproval from './AdminApproval'; // Import the AdminApproval component
import UserHolidayRequests from './UserHolidayRequests';


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user')); // Assuming the user object is stored in localStorage
    if (token && user) {
      setAuthToken(token);
      setIsAuthenticated(true);
      setUserRole(user.role);
    }
  }, []);

  const handleLogin = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user)); // Store user information in localStorage
    setAuthToken(token);
    setIsAuthenticated(true);
    setUserRole(user.role);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthToken(null);
    setIsAuthenticated(false);
    setUserRole(null);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/reset_password" element={<RequestResetPassword />} /> {/* Route for requesting password reset */}
        <Route path="/reset_password/:token" element={<ResetPassword />} /> {/* Route for resetting password with token */}
        <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
          <Route path="/" element={<Layout handleLogout={handleLogout} />}>
            {/* Route accessible by all authenticated users */}
            <Route path="/holidays-request" element={<HolidaysRequest />} />
            <Route path="/list-holidays-request" element={<UserHolidayRequests/>}/>

            {/* Route restricted to Global Administrator role */}
            {userRole === 'Global Administrator' && (
              <Route path="/users" element={<Users />} />
            )}
            {userRole === 'Global Administrator' && (
              <Route path="/admin-approval" element={<AdminApproval />} />
            )}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
