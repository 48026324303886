import React, { useState, useEffect } from 'react';
import { fetchVacationDays, submitHolidayRequest } from './api';
import { toast } from 'react-toastify';

function HolidaysRequest() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vacationDays, setVacationDays] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Nouvelle state pour gérer le chargement

  useEffect(() => {
    const getVacationDays = async () => {
      try {
        const response = await fetchVacationDays();
        setVacationDays(response.data.vacation_days);
      } catch (error) {
        toast.error('Failed to fetch vacation days');
      }
    };
    getVacationDays();
  }, []);

  const calculateDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);

    if (endDate) {
      const daysRequested = calculateDays(selectedStartDate, endDate);
      if (daysRequested > vacationDays) {
        const adjustedEndDate = new Date(selectedStartDate);
        adjustedEndDate.setDate(adjustedEndDate.getDate() + vacationDays - 1);
        setEndDate(adjustedEndDate.toISOString().split('T')[0]);
        setMessage(`End date adjusted to stay within ${vacationDays} days.`);
      } else {
        setMessage('');
      }
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;

    // Validation pour vérifier que la date de fin est après la date de début
    if (new Date(selectedEndDate) < new Date(startDate)) {
      setMessage('The end date cannot be before the start date.');
      return;
    }

    const daysRequested = calculateDays(startDate, selectedEndDate);
    
    if (daysRequested > vacationDays) {
      setMessage(`You can't select more than ${vacationDays} days.`);
    } else {
      setEndDate(selectedEndDate);
      setMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const daysRequested = calculateDays(startDate, endDate);
    if (daysRequested > vacationDays) {
      setMessage(`You have only ${vacationDays} vacation days available.`);
      return;
    }

    setLoading(true); // Déclencher le chargement

    try {
      await submitHolidayRequest(startDate, endDate);
      setMessage('Holiday request submitted successfully');
      toast.success('Holiday request submitted successfully');
    } catch (error) {
      setMessage('Failed to submit holiday request');
      toast.error('Failed to submit holiday request');
    } finally {
      setLoading(false); // Désactiver le chargement une fois terminé
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 py-12 px-4">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-semibold mb-6 text-center">Request Holidays</h1>
        <form onSubmit={handleSubmit}>
          <p className="mb-4 p-4 bg-gold text-white text-lg font-bold rounded text-center">
            Please note: The start date is the first day of your holiday, and the end date is included as the last day of your holiday.
          </p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">End Date (included):</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="w-full px-3 py-2 border rounded"
              required
              disabled={!startDate} // Désactiver le champ si la date de début n'est pas encore sélectionnée
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gold text-white py-2 px-4 rounded hover:opacity-85"
            disabled={loading || calculateDays(startDate, endDate) > vacationDays} // Désactiver le bouton pendant le chargement
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit Request'
            )}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        <div className="mt-4 text-center">
          <p className="text-gray-700">You have {vacationDays} vacation days available.</p>
        </div>
      </div>
    </div>
  );
}

export default HolidaysRequest;
