import React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

const Layout = ({ handleLogout }) => {
  const navigate = useNavigate();

  // Récupérer le rôle de l'utilisateur depuis le localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user ? user.role : null;

  const logout = () => {
    handleLogout();
    navigate('/login');
  };

  return (
    <div className="flex min-h-screen">
      <div className="w-1/5 bg-gray-900 text-white p-6 flex flex-col justify-between">
        <div>
          <h2 className="text-4xl mb-6 font-bold">
            <span>OPUS</span> <span className="text-opusYellow">TRIPAK HR</span>
          </h2>
          <ul className="space-y-4">
            {/* Lien Users visible uniquement pour les administrateurs */}
            {userRole === 'Global Administrator' && (
              <>
              <li>
                <Link 
                  to="/users" 
                  className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                >
                  Users
                </Link>
              </li>
              <li>
              <Link 
                to="/admin-approval" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Pending Holiday Request
              </Link>
            </li>
            </>
            )}
            {userRole === 'Common User' && (
              <>
            <li>
              <Link 
                to="/holidays-request" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                Holidays Requests
              </Link>
            </li>
            <li>
              <Link 
                to="/list-holidays-request" 
                className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
              >
                List Holidays Requests
              </Link>
            </li>
            </>
            )}
          </ul>
        </div> 
        <button
          onClick={logout}
          className="mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
        >
          Logout
        </button>
      </div>
      <div className="w-4/5 p-6 bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
