import React, { useState, useEffect } from 'react';
import { fetchHolidayRequests, approveHolidayRequest, denyHolidayRequest } from './api';
import { toast } from 'react-toastify';

function AdminApproval() {
  const [requests, setRequests] = useState([]);
  const [denyReasons, setDenyReasons] = useState({});

  useEffect(() => {
    const loadHolidayRequests = async () => {
      try {
        const response = await fetchHolidayRequests();
        setRequests(response.data);
      } catch (error) {
        toast.error('Failed to fetch holiday requests');
      }
    };

    loadHolidayRequests();
  }, []);

  const handleApprove = async (id) => {
    try {
      await approveHolidayRequest(id);
      toast.success('Holiday request approved successfully');
      setRequests(requests.filter(request => request.id !== id));
    } catch (error) {
      toast.error('Failed to approve holiday request');
    }
  };

  const handleDeny = async (id) => {
    if (!denyReasons[id]) {
      toast.error('Please provide a reason for denial');
      return;
    }

    try {
      await denyHolidayRequest(id, denyReasons[id]);
      toast.success('Holiday request denied successfully');
      setRequests(requests.filter(request => request.id !== id));
      setDenyReasons(prevReasons => ({ ...prevReasons, [id]: '' }));
    } catch (error) {
      toast.error('Failed to deny holiday request');
    }
  };

  const handleDenyReasonChange = (id, value) => {
    setDenyReasons(prevReasons => ({
      ...prevReasons,
      [id]: value
    }));
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()+1).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Approve or Deny Holiday Requests</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead className="bg-gold">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">End Date (included)</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Vacation Days Left</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Created At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {requests.map((request) => (
              <tr key={request.id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{`${request.firstName} ${request.lastName}`}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.start_date)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.end_date)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.vacation_days}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDateTime(request.created_at)}</div>
                </td>
                <td>
                  <textarea
                    placeholder="Reason for denial"
                    value={denyReasons[request.id] || ''}
                    onChange={(e) => handleDenyReasonChange(request.id, e.target.value)}
                    className="w-full px-3 py-2 border rounded mt-2"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap flex flex-col space-y-2">
                  <button
                    onClick={() => handleApprove(request.id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleDeny(request.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
                  >
                    Deny
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminApproval;
