import axios from 'axios';

const api = axios.create({
  baseURL: 'https://tripak.opus-international.co.uk/api',
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const login = (email, password) => {
  return api.post('/login', { email, password });
};

// Nouvelle fonction pour récupérer les utilisateurs
export const fetchUsers = () => {
  return api.get('/admin/users');
};

// Nouvelle fonction pour créer un utilisateur
export const createUser = (userData) => {
  return api.post('/admin/users', userData);
};

export const updateUser = (id, updatedData) => {
  return api.put(`/admin/users/${id}`, updatedData);
};

export const deleteUser = (id) => {
  return api.delete(`/admin/users/${id}`);
}

// Demande de réinitialisation de mot de passe
export const requestResetPassword = (email) => {
  return api.post('/request-reset-password', { email });
};

// Réinitialisation du mot de passe avec le jeton
export const resetPassword = (token, password) => {
  return api.post(`/reset-password/${token}`, { password });
};

export const fetchUserHolidayRequests = () => {
  return api.get('/user/holiday-requests');
}

export const fetchVacationDays = () => {
  return api.get('/vacation-days');
};

export const submitHolidayRequest = (startDate, endDate) => {
  return api.post('/vacation-request', { startDate, endDate });
};

export const fetchHolidayRequests = () => {
  return api.get('/admin/holiday-requests');
};

export const approveHolidayRequest = (id) => {
  return api.post(`/admin/holiday-requests/${id}/approve`);
};

export const denyHolidayRequest = (id, reason) => {
  return api.post(`/admin/holiday-requests/${id}/deny`, { reason });
};



export default api;
