import React, { useState, useEffect } from 'react';
import { fetchUserHolidayRequests } from './api';
import { toast } from 'react-toastify';

function UserHolidayRequests() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const loadHolidayRequests = async () => {
      try {
        const response = await fetchUserHolidayRequests();
        setRequests(response.data);
      } catch (error) {
        toast.error('Failed to fetch holiday requests');
      }
    };

    loadHolidayRequests();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont de 0 à 11
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  console.log(requests);

  const getStatusText = (approved) => {
    if (approved === "accepted") return 'Approved';
    if (approved === "rejected") return 'Denied';
    return 'Pending';
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Your Holiday Requests</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead className="bg-gold">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">End Date (included)</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason (if denied)</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {requests.map((request) => (
              <tr key={request.id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.start_date)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.end_date)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className={`text-sm font-semibold ${request.status === "accepted" ? 'text-green-500' : request.status === "rejected" ? 'text-red-500' : 'text-yellow-500'}`}>
                    {getStatusText(request.status)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.deny_reason || 'N/A'}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserHolidayRequests;
